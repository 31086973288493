import React, { useEffect, useState } from "react"
import animationFunctions from "../scripts/animationFunctions"

export default function HomeAnimation(props) {
    const [showAnimation, setShowAnimation] = useState(props.showAnimation)
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        if ((/true/i).test(showAnimation)) {
          if (animationFunctions.isLargeScreen()) {
            animationFunctions.startDesktopAnimation();
          }
          else {
            animationFunctions.startMobileAnimation();
          }
        }

        setHasMounted(true);
      }, []);

      if (!hasMounted) {
        return null;
      }

      return (<div>
                <div className="container d-none d-md-flex flex-row justify-content-center desktopDevices">
                    {
                        !showAnimation &&
                        <div className="desktop desktopNoAnimation d-flex flex-column align-items-center device">
                            <div className="desktopCamera"></div>
                            <div className="desktopScreen d-flex flex-column justify-content-center align-items-center">
                                <span className="desktopText d-flex flex-column justify-content-center align-items-center">
                                    <div className="mainDesktopText mainDesktopTextNoAnimation">BURTLE</div>
                                    <div className="subDesktopText subDesktopTextNoAnimation">WEB SERVICES</div>
                                    <div className="sloganText sloganTextNoAnimation">MODERN WEBSITES FOR THE MODERN BUSINESS</div>
                                </span>
                            </div>
                        </div>
                    }
                    {
                        !showAnimation &&
                        <div className="phone phoneNoAnimation d-flex flex-column align-items-center device">
                            <div className="phoneHeader d-flex">
                                <div className="phoneCamera"></div>
                                <div className="phoneSpeaker"></div>
                            </div>
                            <div className="h-100 d-flex justify-content-center">
                                <span className="phoneText d-flex flex-column justify-content-center">
                                    <span className="text-nowrap">
                                        <span className="hiddenUntilDone hiddenUntilDoneNoAnimation">Building </span>beautiful<br/>
                                        <span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> websites that are </span>unique<span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> and </span><br/>
                                        responsive<span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> for compatibility <br/>
                                        with all devices.</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    }
                    {
                        showAnimation &&
                        <div className="phone d-flex flex-column align-items-center device">
                            <div className="phoneHeader d-flex">
                                <div className="phoneCamera"></div>
                                <div className="phoneSpeaker"></div>
                            </div>
                            <div className="h-100 d-flex justify-content-center">
                                <span className="phoneText d-flex flex-column justify-content-center">
                                    <span className="text-nowrap">
                                        <span className="hiddenUntilDone">Building </span>beautiful<br/>
                                        <span className="hiddenUntilDone"> websites that are </span>unique<span className="hiddenUntilDone"> and </span><br/>
                                        responsive<span className="hiddenUntilDone"> for compatibility <br/>
                                        with all devices.</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                    }
                </div>
                <div className="container d-flex d-md-none flex-column justify-content-center align-items-center mobileDevices">
                {
                    showAnimation &&
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="mainDesktopText">BURTLE</div>
                        <div className="subDesktopText">WEB SERVICES</div>
                        <div className="sloganText">MODERN WEBSITES FOR THE MODERN BUSINESS</div>
                        <br/>
                        <div className="phone d-flex flex-column align-items-center device">
                            <div className="phoneHeader d-flex">
                                <div className="phoneCamera"></div>
                                <div className="phoneSpeaker"></div>
                            </div>
                            <div className="h-100 d-flex justify-content-center">
                                <span className="phoneText d-flex flex-column justify-content-center align-items-center">
                                <span className="text-nowrap">
                                    <span className="hiddenUntilDone">Building </span><br/>
                                    <span className="orangeText" id="phoneText1">beautiful</span><span className="hiddenUntilDone"> websites</span><br/> 
                                    <span className="hiddenUntilDone">that are </span><span className="orangeText" id="phoneText2">unique</span><span className="hiddenUntilDone"> and</span><br/> 
                                    <span className="orangeText" id="phoneText3">responsive</span><span className="hiddenUntilDone"> for</span><br/> 
                                    <span className="hiddenUntilDone">compatibility with<br/>
                                    all devices.</span>
                                </span>
                                </span>
                            </div>
                        </div>
                    </div>
                }
                {
                    !showAnimation &&
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <div className="mainDesktopText mainDesktopTextNoAnimation">BURTLE</div>
                        <div className="subDesktopText subDesktopTextNoAnimation">WEB SERVICES</div>
                        <div className="sloganText sloganTextNoAnimation">MODERN WEBSITES FOR THE MODERN BUSINESS</div>
                        <br/>
                        <div className="phone phoneNoAnimation d-flex flex-column align-items-center device">
                            <div className="phoneHeader d-flex">
                                <div className="phoneCamera phoneCameraNoAnimation"></div>
                                <div className="phoneSpeaker phoneSpeakerNoAnimation"></div>
                            </div>
                            <div className="h-100 d-flex justify-content-center">
                                <span className="phoneText d-flex flex-column justify-content-center align-items-center">
                                <span className="text-nowrap">
                                    <span className="hiddenUntilDone hiddenUntilDoneNoAnimation">Building </span><br/>
                                    <span className="orangeText orangeTextNoAnimation" id="phoneText1">beautiful</span><span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> websites</span><br/> 
                                    <span className="hiddenUntilDone hiddenUntilDoneNoAnimation">that are </span><span className="orangeText orangeTextNoAnimation" id="phoneText2">unique</span><span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> and</span><br/> 
                                    <span className="orangeText orangeTextNoAnimation" id="phoneText3">responsive</span><span className="hiddenUntilDone hiddenUntilDoneNoAnimation"> for</span><br/> 
                                    <span className="hiddenUntilDone hiddenUntilDoneNoAnimation">compatibility with<br/>
                                    all devices.</span>
                                </span>
                                </span>
                            </div>
                        </div>
                    </div>
                }
                </div>
            </div>
      )
}