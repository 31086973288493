import React from "react"
import "../styles/style.sass"
import queryString from 'query-string'
import HomeAnimation from '../components/HomeAnimation'
import SEO from "../components/SEO"
import Layout from "../components/Layout"

// markup
const IndexPage = ({location}) => { 
  var showAnimation = false;

  if (queryString.parse(location.search).showAnimation === undefined) {
    showAnimation = true;
  }
  else {
    showAnimation = (/true/i).test(queryString.parse(location.search).showAnimation);
  }

  return (
    <Layout>
      <SEO title="Burtle Web Services - Chatham, IL" description="Burtle Web Services builds beautiful websites that are unique and responsive for compatibility with all devices." />
      <HomeAnimation showAnimation={ showAnimation } />
    </Layout>
  )
}

export default IndexPage