import TweenLite from "gsap";
import $ from "jquery";

class animationFunctions {
  static isLargeScreen() {
    return window.matchMedia("(min-width: 768px)").matches;
  }

  static startDesktopAnimation() {
    setTimeout(function () {
      TweenLite.fromTo(
        $(".phone"),
        0.5,
        { rotation: -90 },
        { rotation: 0, marginLeft: "5vw" }
      );
    }, 1500);
    setTimeout(function () {
      TweenLite.fromTo($(".phoneText"), 0.5, { rotation: 90 }, { rotation: 0 });
    }, 2000);
    setTimeout(function () {
      TweenLite.fromTo($(".hiddenUntilDone"), 0.5, {}, { color: "black" });
    }, 2500);

    if ($('.desktopDevices:contains("desktopCamera")').length == 0) {
      setTimeout(function () {
        $(
          ".desktopDevices"
        ).prepend(`<div class="desktop d-flex flex-column align-items-center device">
                                                        <div class="desktopCamera"></div>
                                                        <div class="desktopScreen d-flex flex-column justify-content-center align-items-center">
                                                          <span class="desktopText d-flex flex-column justify-content-center align-items-center">
                                                            <div class="mainDesktopText">BURTLE</div>
                                                            <div class="subDesktopText">WEB SERVICES</div>
                                                            <div class="sloganText">MODERN WEBSITES FOR THE MODERN BUSINESS</div>
                                                          </span>
                                                        </div>
                                                      </div>`);
      }, 3500);
    }

    setTimeout(function () {
      TweenLite.fromTo($(".mainDesktopText"), 0.85, {}, { opacity: 1 });
    }, 4100);
    setTimeout(function () {
      TweenLite.fromTo($(".subDesktopText"), 0.85, {}, { opacity: 1 });
    }, 4100);
    setTimeout(function () {
      TweenLite.fromTo($(".sloganText"), 0.85, {}, { opacity: 1 });
    }, 4500);
  }

  static startMobileAnimation() {
    setTimeout(function () {
      TweenLite.to($("#phoneText1"), 0.5, { opacity: 1 });
    }, 500);
    setTimeout(function () {
      TweenLite.to($("#phoneText2"), 0.75, { opacity: 1 });
    }, 1250);
    setTimeout(function () {
      TweenLite.to($("#phoneText3"), 0.75, { opacity: 1 });
    }, 2000);
    setTimeout(function () {
      TweenLite.fromTo(
        $(".phoneSpeaker"),
        0.75,
        { borderColor: "rgba(255,255,255,1)" },
        { borderColor: "rgba(0,0,0,1)" }
      );
    }, 2750);
    setTimeout(function () {
      TweenLite.fromTo(
        $(".phoneCamera"),
        0.75,
        { borderColor: "rgba(255,255,255,1)" },
        { borderColor: "rgba(0,0,0,1)" }
      );
    }, 2750);
    setTimeout(function () {
      TweenLite.fromTo(
        $(".phone"),
        0.75,
        { borderColor: "rgba(255,255,255,1)" },
        { borderColor: "rgba(0,0,0,1)" }
      );
    }, 2750);
    setTimeout(function () {
      TweenLite.fromTo($(".hiddenUntilDone"), 0.75, {}, { color: "black" });
    }, 3500);
  }
}

export default animationFunctions;
